<template>
  <v-theme-provider>
    <base-section
      id="news-latest"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <base-section-heading title="Penghargaan"/>
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="data in posts"
                    :key="data.web_news_id"
                    md="4"
                    xs="12"
                    cols="12"
                  >
                    <v-card
                    outlined
                    @click="Detail(data.web_news_id)">
                      <v-img
                        :src="require('@/assets/awards.jpg')"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="300px"
                        :alt="data.web_news_name"
                      >
                        <v-card-title v-html="data.web_news_name" class="body-2"></v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ MaxLength(data.web_news_name,50) }}
                        <v-spacer></v-spacer>
                        <!-- <v-btn icon @click="Share(data.web_news_id)">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <!-- <v-col class="text-center mt-3">
                  <base-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    color="accent"
                    large
                    outlined
                    to="penghargaan-perusahaan"
                    class = "text-capitalize"
                  >
                    Penghargaan Kami Lainnya
                  </base-btn>
                </v-col> -->
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNewsLatest',
    data: () => ({
        posts: [],
    }),
    created () {
        this.List()
    },
    methods: {
        List () {
          this.posts = [
                        {'web_news_id':'1','web_news_name':'Predikat Sangat Bagus Kinerja Keuangan 2023'},
                        {'web_news_id':'2','web_news_name':'Predikat Sangat Bagus Kinerja Keuangan 2024'},
                        {'web_news_id':'3','web_news_name':'Predikat Sangat Bagus Kinerja Keuangan 2025'},
                        ]
          // this.loading  = true
          // let formdata = {
          //       limit: '3',
          //       web_news_type: 'awards',
          //       web_news_actived: 'Y',
          //       order_by: 'web_news_sort',
          //       order_type: 'ASC',
          // }
          // let param      = this.$functions.ParamPOST(formdata)
          // this.$axios.post(this.$functions.UrlPOST('apiWebNewsList'),param,{
          // headers: {
          //     'Content-Type': 'text/plain; charset=ISO-8859-1'
          // }
          // })
          // .then(response => {
          //     this.posts = response.data
          //     this.loading  = false
          // })
          // .catch(e => {
          //     alert(e)
          //     this.loading  = false
          // })
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Share (url) {
          let paramurl = this.$functions.AppURL() +'/penghargaan-perusahaan/' + url
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        },
        Detail (url) {
          this.$router.push({name: 'penghargaan-detail', params: { url: url }})
        },
    }
  }
</script>
